<template>
  <div class="test_home_page">
    <ul>
      <li
        v-for="(item, index) in testListData"
        :key="index"
        @click="handleClick(item.key, item)"
      >
        <div class="text">
          <div class="label">{{ item.name }}</div>
          <div class="intro_test">{{ item.intro }}</div>
        </div>

        <div><img :src="item.img" /></div>
      </li>
    </ul>

    <card-bind
      :showModal="showModal"
      @closeModal="hideModal"
      @bindCard="bindVipCard"
      :routePath="routePath"
      :reload="false"
      :vipType="vipType"
    ></card-bind>
  </div>
</template>

<script>
import { mapState } from "vuex";
import cardBind from "@/components/cardBind";

export default {
  components: { cardBind },
  data() {
    return {
      vipType: 1,
      routePath:"",
      isVip: false,
      showModal: false,
      testListData: [
        {
          name: "职业规划测评",
          key: 1,
          intro:
            "清晰了解学生天赋、兴趣、个性和适宜专业，帮助您达到专业和优势、兴趣相结合",
          img: require("@/assets/imgs/test1.png"),
          routePath: '/assessmentSystem/careerPlanning',
        },
        {
          name: "新高考选科测评",
          key: 2,
          intro: "清晰了解学生学科倾向，学科能力，帮助您推荐适宜的选科",
          img: require("@/assets/imgs/test2.png"),
          routePath: '/assessmentSystem/selectCourse',
        },
        {
          name: "学习状态测评",
          key: 3,
          intro: "清晰了解孩子当前各维度学习状态和适宜的提升方法",
          img: require("@/assets/imgs/test3.png"),
          routePath: '/assessmentSystem/learningState',
        }
      ]
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.userinfo
    })
  },
  methods: {
    hideModal() {
      this.showModal = false;
    },
    bindVipCard(f) {
      this.isVip = f;
      if (f) {
        this.$router.push({ path: this.routePath });
      }
    },
    handleClick(key, item) {
      this.routePath = item.routePath;
      if(!this.userInfo.can_test){
        this.showModal = true;
        return;
      }
      
      if (key == 1) {
        //职业测评
        if (!this.userInfo.test_status.zhiye) {
          this.$router.push({ name: "CareerPlanningTest" });
        } else {
          this.toTestResult("career");
        }
      } else if (key == 2) {
        //选科测评
        if (!this.userInfo.test_status.gaokao) {
          this.$router.push({ name: "SelectCourseTest" });
        } else {
          this.toTestResult("course");
        }
      } else if (key == 3) {
        //学习状态测评
        if (!this.userInfo.test_status.xuexi) {
          this.$router.push({ name: "LearningStateTest" });
        } else {
          this.toTestResult("learning");
        }
      }
    },
    toTestResult(type) {
      this.$router.push({ name: "MyEvaluations", params: { type: type } });
    }
  }
};
</script>

<style lang="scss" scoped>
.test_home_page {
  height: 100vh;
  overflow: hidden;
  background: #fff;
  ul {
    padding: 0 0.5rem;
    margin-top: 1rem;
    li {
      background: #fff;
      height: 2.2rem;
      border-radius: 10px;
      margin-bottom: 0.6rem;
      color: #fff;
      font-size: 0.35rem;
      padding: 0.32rem 0.3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text{
        flex-basis: 60%;
      }
      img {
        width: 2.2rem;
      }
      &:nth-child(1) {
        background: #a35ce4; 
      }
      &:nth-child(2) {
        background: #24e4b8;
      }
      &:nth-child(3) {
        background: #f9a472;
      }
      .label {
        margin-bottom: 0.12rem;
      }
      .intro_test {
        font-size: 0.24rem;
        color: #f7f7f7;
        line-height: 22px;
      }
    }
  }
}
</style>
